@tailwind base;
@tailwind components;
@tailwind utilities;

.homePage {
  width: 100vw;
  height: 100vh;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body {
  height: 100vh;
  margin: 0px;
  padding: 0px;
}

.aboutPage {
  height: 100%;
}

.spinner {
  z-index: 99;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.headerMain {
  display: inline;
}
.headerMain:hover{
  color: white;
}

h1:hover ~ h3{
  color: white;
}

.sun {
  width: 200px;
  height: 200px;
  position: relative;
  text-align: center;
}

.profilePic {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  border-radius: 50%;
}


.aboutPlanet {
  width: 260px;
  height: 260px;
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -25%);
  -webkit-transform: translate(-50%, -25%);

  animation: aboutPlanetOrbit 15s infinite linear;  
  -webkit-animation: aboutPlanetOrbit 15s infinite linear;
  
  
  border: 1px solid #4B52ED;
  border-radius: 50%;
}

.aboutIcon {
  width: 40px;
  height: 40px;

  transform: translate(100%, 15%);
  -webkit-transform: translate(100%, 15%);

  position: relative;
  text-align: center;
  color: #4B52ED;
}

.aboutText {
    position: relative;
    color: white;
    
}

.aboutIcon:hover {
  opacity: 0.5;
}

.experiencePlanet {
  width: 440px;
  height: 440px;
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -100%);
  -webkit-transform: translate(-50%, -33%);

  animation: experiencePlanetOrbit 25s infinite linear;   
  -webkit-animation: experiencePlanetOrbit 25s infinite linear;

  border: 1px solid #4B52ED;
  border-radius: 50%;
  text-align: center;
}



.rocketIcon {
  width: 40px;
  height: 40px;
  left: 80%;
  top: 10%;
  position: relative;
  text-align: center;
  color: #4B52ED;
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .rocketIcon {
      left: 65%;
      top: 50%;
    }
  }
}

.rocketIcon:hover {
  opacity: 0.5;
}

.projectsPlanet {
  width: 615px;
  height: 615px;
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -100%);
  -webkit-transform: translate(-50%, -37%);

  animation: projectsPlanetOrbit 35s infinite linear;

  border: 1px solid #4B52ED;
  border-radius: 50%;
  text-align: center;
}

.meteorIcon {
  width: 40px;
  height: 40px;
  left: 80%;
  top: 10%;
  position: relative;
  text-align: center;
  color: #4B52ED;
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .meteorIcon {
      left: 65%;
      top: 50%;
    }
  }
}

.meteorIcon:hover {
  opacity: 0.5;
}


@keyframes aboutPlanetOrbit {
  0% {
    transform: translate(-50%, -25%) rotate(-360deg);
  }
  25% {
    transform: translate(-50%, -25%) rotate(-270deg);
  }
  50% {
    transform: translate(-50%, -25%) rotate(-180deg);
  }
  75% {
    transform: translate(-50%, -25%) rotate(-90deg);
  }
  100% {
    transform: translate(-50%, -25%) rotate(0deg);
  }
}



@keyframes experiencePlanetOrbit {
  0% {
      transform: translate(-50%, -33%) rotate(360deg);
    }
  
    25% {
      transform: translate(-50%, -33%) rotate(270deg);
    }
  
    50% {
      transform: translate(-50%, -33%) rotate(180deg);
    }
  
    75% {
      transform: translate(-50%, -33%) rotate(90deg);
    }
  
    100% {
      transform: translate(-50%, -33%) rotate(0deg);
    }
}



@keyframes projectsPlanetOrbit {
  0% {
      transform: translate(-50%, -37%) rotate(-360deg);
    }
  
    25% {
      transform: translate(-50%, -37%) rotate(-270deg);
    }
  
    50% {
      transform: translate(-50%, -37%) rotate(-180deg);
    }
  
    75% {
      transform: translate(-50%, -37%) rotate(-90deg);
    }
  
    100% {
      transform: translate(-50%, -37%) rotate(0deg);
    }
}


@keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-moz-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-ms-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

@keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-webkit-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-moz-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-ms-keyframes move-clouds-back {
  from {background-position: 0;}
  to {background-position:10000px 0;}
}

.stars, .twinkling, .clouds {
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
height:100%;
display:block;
}

/*
Shoutout to this guy on Codepen for the awesome star graphics
*/
.stars {
background: transparent url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
z-index: 0;
}

.statsBar {
  width: 90%;
  height: 15%;
  margin-left: 5%;
  margin-right: 5%;
}

.repoHeader {
  width: 90%;
  height: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.experienceHeader {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  
}

.skillsCoursework {
  width: 97%;
  height: 70%;
}

.skills {
  height: 50%;
}

.bottomExpCards {
  margin-left: 5%;
  margin-right: 3.5%;
}

.githubPic {
  height: 15%;
  width: 15%;
}

.aboutPic {
  height: 100%;
  width: 200px;
}

.experiencePic {
  height: 100%;
  width: 300px;
}

.githubPic:hover {
  opacity: 0.75;
}

.projectsHeader {
  text-align: center;
}

.hotButtons {
  width: 280px;
  margin-left: 0px;
  margin-right: auto;
}

.hotButtons a{
  margin-left: 40%;
}

.hotButtons svg:hover {
  color: white;
}

.positionItem {
  height: 95%;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  white-space: pre-line;
}

.courseItem {
  height: 15%;
}

.aboutmeHead {
  place-self: center;
  height: 7%;
}

.aboutHeader {
  width: 90%;
  height: 95%;
  margin-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
}

.picBanner {
  border-radius: 15%;
}

.picGrid {
  height: 40%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}